<template>
  <!-- Footer -->
  <footer class="bg-white w-100 p-3">
    <div class="">
      <div class="row">
        <div class="col-sm-5 col-md-6 col-lg-6">
          <ul class="list-inline mb-0 left-text">
            <slot name="left">
            </slot>
          </ul>
        </div>
        <div class=" col-sm-7 col-md-6 col-lg-6 text-right text-right">
          <slot name="right">
          </slot>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer END -->
</template>
<script>
import { socialvue } from "../../../config/pluginInit"
export default {
  name: "FooterContent",
  mounted () {
    socialvue.index()
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width:567px) and (min-width:320px)  {
  .left-text{
    text-align: center;
  }
  .text-right{
    text-align: center !important;
  }
}
</style>
