<template>
  <div>
    <Loader />
    <div class="wrapper">
      <!-- TOP Nav Bar -->
      <NavbarContent/>
      <!-- Right Sidebar Panel End-->
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page ">
        <div class="container-fluid p-30px">
          <router-view/>
        </div>
      </div>
    </div>
    <FooterContent>
      <template v-slot:left>
        <li class="list-inline-item">
          <a href="/content/PrivacyPolicy" target="_blank">Privacy Policy</a>
        </li>
        <li class="list-inline-item">
          <a href="/content/Tnc" target="_blank">Terms of Use</a>
        </li>
      </template>
      <template v-slot:right>
        Copyright {{new Date().getFullYear()}} <a href="#">Gide</a> All Rights Reserved.
      </template>
    </FooterContent>
  </div>
</template>
<script>
import { socialvue } from "../config/pluginInit"
import Loader from "../components/socialvue/loader/Loader"
import NavbarContent from "../components/socialvue/navbars/NavbarContent.vue"
import FooterContent from "../components/socialvue/footer/FooterContent.vue"
export default {
  name: "ContentLayout",
  components: {
    FooterContent,
    NavbarContent,
    Loader
  },
  data () {
    return {
      logo: require("../assets_gide/img/logo/gide_logo.png")
    }
  },
  mounted () {
    socialvue.mainIndex()
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
</style>
